<template>
  <div class="card">
    <h3 class="card-title">Visualizar gerencia</h3>
    <template v-if="gerencia">
      <h4 class="">
        <span class="text-secondary">Descripción:</span>
        {{ gerencia.descripcion }}
      </h4>
      <h4 class="">
        <span class="text-secondary">Gerente:</span>
        {{ gerencia.gerente.nombre }}
      </h4>
      <div class="d-flex space-between">
        <button class="btn btn-info" @click="$router.push({name:'ListarGerencias'})">Volver</button>
        <div>
          <button class="btn btn-warning" @click="openEdit = true">Editar gerencia</button>
          <button class="btn btn-error" @click="eliminarGerencia">
            Eliminar gerencia
          </button>
        </div>
      </div>
      <modal v-if="openEdit" @close="openEdit = false">
        <editar-gerencia slot="modal-body" :gerencia="gerencia" @saved="saved"></editar-gerencia>
      </modal>
    </template>
  </div>
</template>
<script>
import { GerenciaServices } from "../services/GerenciaServices";
import Swal from "sweetalert2";
import Modal from "@/components/elements/Modal";
import EditarGerencia from "./EditarGerencia.vue";
export default {
  name: "VerGerencia",
  components:{
    Modal, EditarGerencia
  },
  data() {
    return {
      gerencia: null,
      openEdit: false
    };
  },
  mounted() {
    GerenciaServices.api.find(this.$route.params.id).then((response) => {
      this.gerencia = response.gerencia;
    });
  },
  methods: {
    eliminarGerencia() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar <b>${this.gerencia.descripcion}</b>?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          GerenciaServices.api.delete(this.gerencia).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({ name: "ListarGerencias" });
          });
        }
      });
    },
    saved(){
      this.openEdit = false
      GerenciaServices.api.find(this.gerencia.id).then((response) => {
      this.gerencia = response.gerencia;
    });
    }
  },
};
</script>